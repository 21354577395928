import React, { Fragment, useState, useEffect } from 'react'
import { Container , Row, Col, Button } from 'reactstrap';

import Summary from './Summary'

export default function Confirmation() {
    return (
        <Fragment>
            <Summary title="Reservations Details" />
            <Container>
                <Row>
                    <Col xs={12} className="d-flex justify-content-end">
                        <Button
                            color="primary"
                        >
                            Click for print reservation
                        </Button>
                        <Button
                            color="secondary"
                            className="ml-3"
                        >
                            Return Booking
                        </Button>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    )
}
