import React, { Fragment } from 'react'
import { Container , Row, Col } from 'reactstrap';

import 'moment/locale/cs.js';
import 'moment/locale/es.js';
import 'moment/locale/fr.js';
import 'moment/locale/nl.js';
import moment from 'moment';

export default function Summary(props) {

    const {lang} = props;

    if(lang === 'eng'){
        moment.locale('en');
    }else{
        moment.locale('es');
    }

    return (
        <Fragment>
            <Container className="summary-page">
                <Row className="my-3">
                    <Col xs={8}>
                        <h3>{props.title}</h3>
                    </Col>
                    <Col xs={4} className="text-right">
                        <h4>Total: ${props.totalPayment} USD</h4>
                    </Col>
                </Row>
                <Row className="data-information">
                    <Col xs={12} md={6}>
                        <h5>{ lang === 'eng' ? 'Customer information' : 'Información del cliente'}</h5>
                        <h6>{ lang === 'eng' ? 'Full name:' : 'Nombre:'} <span>{props.data.fullname}</span></h6>
                        <h6>{ lang === 'eng' ? 'Phone:' : 'Telfono:'} <span>{props.data.cellphone}</span></h6>
                        <h6>{ lang === 'eng' ? 'E-mail:' : 'Correo:'} <span>{props.data.email}</span></h6>
                        <h5>{ lang === 'eng' ? 'Transport Selected' : 'Unidad Seleccionada'} </h5> { lang === 'eng' ? '' : ''}
                        <h6>{ lang === 'eng' ? 'Vehicle:' : 'Vehiculo:'} <span>{props.unit.label}</span></h6>
                        <h6>{ lang === 'eng' ? 'Trip type:' : 'Tipo de Servicio'} <span>{props.data.trip_type}</span></h6>
                        <h6>{ lang === 'eng' ? 'Passenger number:' : 'Número de Pasajeros:'} <span>{props.data.total_passengers}</span></h6>
                        <h6>{ lang === 'eng' ? 'Pickup Location:' : 'Ubicación de Recogida'} <span>{props.data.pickup_location}</span></h6>
                        <h6>{ lang === 'eng' ? 'Destination:' : 'Destino:'} <span>{props.data.destination_location}</span></h6>
                    </Col>
                    <Col xs={12} md={6}>
                        <h5>{ lang === 'eng' ? 'Arrival Information:' : 'Información de Arribo:'}</h5>
                        <h6>{ lang === 'eng' ? 'Date / Time:' : 'Fecha / Hora:'} <span>{ moment(props.data.pickup_datetime).format("dddd, MMMM Do YYYY, h:mm:ss a") }</span></h6>
                        <h6>{ lang === 'eng' ? 'Flight Number:' : 'Número de Vuelo:'} <span>{props.data.arrival_flight_number}</span></h6>
                        <h6>{ lang === 'eng' ? 'Airline Name:' : 'Aerolinea:'} <span>{props.data.arrival_airline}</span></h6>
                        <hr />
                        {
                            props.data.trip_type == 'Round Trip' &&
                            <Fragment>
                                <h5>{ lang === 'eng' ? 'Departure information:' : 'Información de Partida:'}</h5>
                                <h6>{ lang === 'eng' ? 'Date / Time:' : 'Fecha / Hora:'} <span>{ moment(props.data.departure_datetime).format("dddd, MMMM Do YYYY, h:mm:ss a") }</span></h6>
                                <h6>{ lang === 'eng' ? 'Flight Number:' : 'Número de Vuelo:'} <span>{props.data.departure_flight_number}</span></h6>
                                <h6>{ lang === 'eng' ? 'Airline Name: ' : 'Aerolinea:'}<span>{props.data.departure_airline}</span></h6>
                            </Fragment>
                        }
                        
                    </Col>
                </Row>
            </Container>
            <hr />
            {
                lang === 'eng' ?
                <div className="reservations-policies px-3">                 
                    <h5>Terms and Conditions</h5>
                    <p>CHANGES AND CANCELLATIONS POLICY</p>
                    <p><strong>In Transportation Services:</strong></p>
                    <ul><li>No charge for changes or cancellations with 24 hour notice.</li><li>24 – 12 hour notice is subject to 50% cancellation charge.</li><li>Less than 12 hours of notice or failure to appear will result in a 100% cancellation charge.</li></ul>
                    <p>NO SHOW POLICY:</p>
                    <ul><li>No show in the arrival or departure service is not refundable.</li></ul>
                    <p>TRANSPORTATION POLICY</p>
                    <p><strong>General Rules Details</strong></p>
                    <ul><li>All private services use toll road (in/out, except early morning and late night services)</li><li>Shuttle services may or may not use the toll road depending on hours and traffic.</li><li>All arrival transportation services include one bottle of water per person</li><li>Hourly and all day rents are allowed only inside the Los Cabos area.(Includes one tank of gasoline)</li></ul>
                    <p><strong>Children:</strong></p>
                    <ul>
                        <li>Children up to 5 years old, half fare.</li>
                        <li>Usage of infant car seat/car seat will apply as half of charge.</li>
                        <li>Infant car seats/car seats are available for US $5.00 each way, but we have very limited infant car seats/Car Seats, We suggest you bring your own, or make and anticipated reservation, otherwise we will not guarantee availability.</li><li>Only private services may stop on the way to Hotels at any store (Costco, Home Depot, Grocery store). We request booking these services at least 72 hours before arrival. There is a charge of US$60.00 per hour.</li><li>If you break or damage any parts (interior /exterior) of the vehicle, a charge will apply.</li></ul>
                    <p><strong>Luggage Rules Details</strong></p>
                    <ul><li>Two pieces of luggage per person are allowed (60LB max), this does not include baby car seat s.</li><li>The use of a wheelchair, bringing surfboards, golf bags, family pets, fishing equipment, large size coolers, and bicycle boxes is allowed only in private services.</li><li>An electric wheelchair does not fit in a regular van, please verify special vehicle availability.</li><li>TRANSROUTE Shuttle service allows two pieces of checked baggage and two carry-on pieces for each passenger. Checked baggage includes most commercially available luggage and moderate sized boxes.</li></ul>
                    <p>No single piece of baggage or property weighing in excess of sixty (60) pounds/ (25) kg will be accepted for transportation unless there is additional help available to aid the driver in both the loading and unloading of such baggage.</p>
                    <p>Pets will require a private van.</p>
                    <p>If anyone or any family has more than six (6) pieces of checked luggage or multiple large boxes you will require a private van.</p>
                    <p>Lost Baggage</p>
                    <p>TRANSROUTE Service will not be liable for lost baggage since baggage is never removed from the passenger’s presence, and is stored in the passenger compartment in which the passenger is riding. Passenger’s baggage remains, at all times, the responsibility of the passenger.</p>
                </div>
                :
                <div className="reservations-policies px-3">                 
                    <h5>Terminos y Condiciones</h5>
                    <p>POLITICA DE CAMBIOS Y CANCELACIONES</p>
                    <p><strong>En servicios de transportación:</strong></p>
                    <ul>
                        <li>Sin cargo por cambios o cancelaciones con 24 horas de anticipación.</li>
                        <li>24 – 12 horas aviso Está sujeto a cargos de cancelación del 50%.</li>
                        <li>Menos de 12 horas de aviso o incomparecencia resultará en un cargo por cancelación del 100%.</li>
                    </ul>

                    <p>POLITICAS DE TRANSPOTACIÓN</p>
                    <p><strong>Política Información General</strong></p>
                    <ul>
                        <li>Todos servicios privados uso toll road (entrada/salida, excepto servicios de tarde en la noche y temprano por la mañana)</li>
                        <li>Tansporte servicios de transporte pueden o no pueden utilizar la carretera de peaje según tráfico y horas.</li>
                        <li>Todos los servicios de transportación incluyen un bote de agua por persona.</li>
                        <li>Servicios de transporte de llegada todos de día de rentas se permiten sólo dentro de la zona de Los Cabos. (Incluye un tanque de gasolina)</li>
                    </ul>
                    <p><strong>Infantes:</strong></p>
                    <ul>
                        <li>Niños hasta 5 años, pagan mitad de tarifa.</li>
                        <li>Uso de silla de coche silla de auto infantil se aplicará en la mitad de carga.</li>
                        <li>Asientos de asientos de coche para bebé están disponibles por US$ 5.00 cada manera, pero tenemos muy limitada asientos coche asientos para bebé, sugerimos traer el suyo propio, o haces y anticipado de la reserva, de lo contrario no se garantiza disponibilidad.</li>
                        <li>Sólo servicios privados pueden dejar en el camino a los hoteles en cualquier tienda (Costco, Home Depot, tienda de comestibles).</li>
                        <li>Si rompe o daña las piezas (interior /exterior) del vehículo, se aplicará un cargo.</li>
                    </ul>
                    <p><strong>Detalles de las reglas de equipaje</strong></p>
                    <ul>
                        <li>Dos piezas de equipaje de equipaje por persona están permitidos (60LB máximo), esto no incluye los s de asiento de coche de bebé.</li>
                        <li>El uso de una silla de ruedas, llevar tablas de surf, bolsas de golf, mascotas de la familia, equipo de pesca, refrigeradores de gran tamaño y cuadros de bicicletas está permitido sólo en servicios privados.</li>
                        <li>Una silla de ruedas eléctrica no encaja en una Urvan, por favor verifique la disponibilidad de vehículos especiales.</li>
                        <li>Servicio de transporte TRANSROUTE permite dos piezas de equipaje facturado y equipaje de mano dos piezas para cada pasajero.</li>
                    </ul>

                    <p>Ninguna pieza de equipaje o propiedad que exceda de 60 sesenta libras de peso (25) kg se aceptarán para el transporte si no hay ayuda adicional disponible para ayudar al conductor en la carga y descarga de tal equipaje.</p>
                    <p>Las mascotas requieren una Urvan privada.</p>
                    <p>Si alguien o alguna familia tiene más de seis 6 piezas de equipaje facturado o múltiples cajas necesita una furgoneta privada.</p>
                    <p>Perdida de equipaje:</p>
                    <p>TRANSROUTE servicio no será responsable por pérdida de equipaje nunca se quita de la presencia del pasajero, y se almacena en el habitáculo en el que el pasajero está en. Equipaje del pasajero sigue siendo, en todo momento, la responsabilidad del pasajero.</p>
                </div>
            }
            
            
        </Fragment>
    )
}