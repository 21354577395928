import React, { Fragment } from 'react';
import { Row, Col } from 'reactstrap';
import 'moment/locale/cs.js';
import 'moment/locale/es.js';
import 'moment/locale/fr.js';
import 'moment/locale/nl.js';

import moment from 'moment';

export default function Sidebar({ data, unit, lang, totalPayment, validator, tripValidator }) {

    if(lang === 'eng'){
        moment.locale('en');
    }else{
        moment.locale('es');
    }
    console.log(unit);
    return (
        <Fragment>
            <div className="summary-content">
                <h3>{lang === 'eng' ? 'Summary' : 'Resumen'}</h3>
                <h4>TOTAL: <span>${totalPayment}</span></h4>
                <h4>{lang === 'eng' ? 'Transport Selected:' : 'Transporte Seleccionado'}</h4>
                <img className="img-fluid" src={unit.image !== 'No image' ? unit.image : null} />
                <p>{lang === 'eng' ? 'Service:' : 'Servicio:'}<br /> <span>{data.trip_type}</span></p>

                <Row className="px-0">
                    <Col xs={6}>
                        <p>{lang === 'eng' ? 'Transport:' : 'Transporte:'}<br /> <span>{unit.label}</span></p>
                    </Col>
                    <Col xs={6}>
                        <p>{lang === 'eng' ? 'Passenger:' : 'Pasajeros:'}<br /> <span>{data.total_passengers}</span></p>
                    </Col>
                </Row>

                <p>{lang === 'eng' ? 'Pickup Location:' : 'Ubicación de recogida:'}<br /> <span>{data.pickup_location}</span></p>
                <p>{lang === 'eng' ? 'Destination Location:' : 'Ubicación de destino:'}<br /> <span>{data.destination_location}</span></p>
                <h4>{lang === 'eng' ? tripValidator ? 'Departure Information' : 'Arrival Information:' : tripValidator ? 'Información de partida' : 'Información de llegada'}</h4>
                <p>{lang === 'eng' ? 'Pickup Date/Time:' : 'Fecha/Hora de recogida'} <br /> <span>{moment(data.pickup_datetime).format("dddd, MMMM Do YYYY, h:mm:ss a")}</span></p>

                {validator &&
                    <Row className="px-0">
                        <Col xs={6}>
                            <p>{lang === 'eng' ? "Airline's name:" : 'Nombre de la aerolínea:'}<br /> <span>{data.arrival_airline}</span></p>
                        </Col>
                        <Col xs={6}>
                            <p>{lang === 'eng' ? "Flight's number:" : 'Número de vuelo:'}<br /> <span>{data.arrival_flight_number}</span></p>
                        </Col>
                    </Row>
                }

                {data.trip_type == 'Round Trip' &&
                    <Fragment>
                        <h4>{lang === 'eng' ? 'Departure Information:' : 'Información de partida:'}</h4>
                        <p>{lang === 'eng' ? 'Pickup Location:' : 'Ubicación de recogida:'}<br /> <span>{moment(data.departure_datetime).format("dddd, MMMM Do YYYY, h:mm:ss a")}</span></p>

                        {validator &&
                            <Row className="px-0">
                                <Col cs={6}>
                                    <p>{lang === 'eng' ? "Airline's name:" : 'Nombre de la aerolínea:'}<br /> <span>{data.departure_airline}</span></p>
                                </Col>
                                <Col xs={6}>
                                    <p>{lang === 'eng' ? "Flight's number:" : 'Número de vuelo:'}<br /> <span>{data.departure_flight_number}</span></p>
                                </Col>
                            </Row>
                        }
                    </Fragment>
                }
                {validator &&
                    <Fragment>
                        <h4>{lang === 'eng' ? 'Contact / Traveler Information' : 'Información de Contacto / Viajero :'}</h4>
                        <p>{lang === 'eng' ? 'Full name:' : 'Nombre completo:'} <br /> <span>{data.fullname}</span></p>
                        <p>{lang === 'eng' ? 'Phone / Cell number:' : 'Teléfono:'} <br /> <span>{data.cellphone}</span></p>
                        <p>Email:<br /> <span>{data.email}</span></p>
                    </Fragment>
                }
            </div>
        </Fragment>
    )
}
