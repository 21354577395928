import React, { Fragment } from 'react'
import { Container , Row, Col } from 'reactstrap';

export default function Summary({title}) {

    return (
        <Fragment>
            <Container className="summary-page">
                <Row className="my-3">
                    <Col xs={8}>
                        <h3>{title}</h3>
                    </Col>
                    <Col xs={4} className="text-right">
                        <h4>Total: $94.4 USD</h4>
                    </Col>
                </Row>
                <Row className="data-information">
                    <Col xs={12} md={6}>
                        <h5>Customer information</h5>
                        <h6>Full name: <span>Dannielle toles</span></h6>
                        <h6>Phone: <span>315 5859168</span></h6>
                        <h6>E-mail: <span>dani_48221@yahoo.com</span></h6>
                        <h5>Transport selected</h5>
                        <h6>Vehicle: <span>Sedan</span></h6>
                        <h6>Trip type: <span>Round Trip</span></h6>
                        <h6>Passenger number: <span>4</span></h6>
                        <h6>Pickup location: <span>Airport SJD</span></h6>
                        <h6>Destination: <span>PUEBLO BONITO SUNSET BEACH</span></h6>
                    </Col>
                    <Col xs={12} md={6}>
                        <h5>Arrival Information:</h5>
                        <h6>Date / Time: <span>17/10/2020 12:30:00</span></h6>
                        <h6>Flight Number: <span>1117</span></h6>
                        <h6>Airline Name:: <span>AAL American Airlines</span></h6>
                        <hr />
                        <h5>Departure information:</h5>
                        <h6>Date / Time: <span>17/10/2020 12:30:00</span></h6>
                        <h6>Hotel Departure: <span>PUEBLO BONITO RESORT AT SUNSET BEACH</span></h6>
                        <h6>Flight Number:: <span>1125</span></h6>
                        <h6>Airline Name:: <span>AAL American Airlines</span></h6>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    )
}